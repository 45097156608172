<template>
  <div class="bg-white vh-100">
    <!-- <b-container :fluid="false">
      <div class="px-50 pt-3">
        <h2>Market place</h2>
      </div>
      <b-row>
        
      </b-row>
    </b-container> -->
  </div>
</template>
<script>
export default {
  name: 'ProjectsMarketPlace',
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
