<template>
  <b-container :fluid="false" class="projects-container">
    <div class="px-50">
      <h2>My Projects</h2>
      <hr />
    </div>
    <b-row>
      <b-col v-for="(project, index) in data" :key="`project_${index}`" class="my-25 px-0 d-flex justify-content-center p-0" lg="3" md="4" sm="4">
        <a href="#" @click="SwitchToProject(project.name)" style="width:200px;height: 260px !important;" class="mb-1">
          <b-card no-body class="w-100 h-100 py-0">
            <b-card-header class="bg-primary text-white p-1">
              <div class="mx-auto font-large-5">
                {{ project.name[0].toUpperCase() }}
              </div>
            </b-card-header>
            <b-card-body class="pt-1 pb-0 d-flex flex-column justify-content-between shadow-lg">
              <b-card-text class="p-0 m-0">
                <h3>{{ project.displayName }}</h3>
                <span class="font-small-2">
                  <b-badge class="bg-secondary bg-lighten-2">{{ project.name }}</b-badge>
                </span>
              </b-card-text>
              <!-- <b-button variant="outline-primary w-100" @click="onSelectProject(project)">
              Enter
            </b-button> -->
            </b-card-body>
            <b-card-footer class="border-0 p-0 text-right pb-50 pr-50 bg-white">
              <feather-icon
                class="text-secondary"
                icon="UserIcon"
                size="18"
                v-b-tooltip.hover.v-secondary.top
                v-b-tooltip.hover.html="`Member`"
                v-if="!isProjectOwner(project)"
              />
              <feather-icon class="text-secondary" icon="HomeIcon" size="18" v-b-tooltip.hover.v-secondary.top v-b-tooltip.hover.html="`Owner`" v-else />
            </b-card-footer>
          </b-card>
        </a>
      </b-col>
      <b-col class="my-25 px-0 d-flex justify-content-center" lg="3" md="4" sm="6">
        <b-card no-body class="border-0 shadow-none  bg-transparent w-100 h-100">
          <b-card-body class="pt-1 bg-transparent d-flex flex-column justify-content-center align-items-center">
            <a
              @click="
                startCreateProject = false
                startCreateProject = true
              "
              ><feather-icon icon="PlusIcon" class="font-large-5 font-weight-bold"
            /></a>
          </b-card-body>
          <NewProject v-model="startCreateProject" @project-created="onNewProjectCreated" />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { projects } from '@/api'
import NewProject from './NewProject.vue'

export default {
  name: 'ProjectsList',
  components: { NewProject },
  data() {
    return {
      startCreateProject: false,
      loading: false,
      data: [],
    }
  },
  created() {
    this.startCreateProject = false
    this.fetchData()
  },
  mounted() {},
  methods: {
    async fetchData() {
      const self = this
      self.loading = true

      await projects.getProjects().then(res => {
        self.data = res.data
        self.loading = false
      })
    },
    async onNewProjectCreated() {
      await this.fetchData()
    },
  },
  computed: {},
}
</script>
<style scoped lang="scss">
.projects-container {
  max-width: 910px;
}
</style>
