<template>
  <div>
    <b-modal
      v-model="openModal"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @cancel="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Create"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="LayoutIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            New Project
          </h4>
          <b-card-text>
            Create a blank project
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <page-loader v-if="loading" type="content" :fluid="true" size="small" />
        <div v-if="!loading">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="false">
            <validation-observer ref="newProjectForm">
              <b-form @submit.prevent>
                <b-row class="mb-1">
                  <!-- <b-col md="12" lg="12" class="d-none d-md-block pb-1 text-center">
                    <b-img :src="require('@/assets/images/pages/new-project.png')" class="w-50" />
                  </b-col> -->
                  <b-col md="12" lg="12">
                    <b-row class="px-0 px-lg-2">
                      <b-col md="12" lg="12">
                        <text-input
                          v-model="data.displayName"
                          size="lg"
                          placeholder="ex: Catalog App, Web Site.... "
                          label="Display Name"
                          label-class="font-weight-bold"
                          validation-rules="required"
                          @input="createKey"
                        />
                      </b-col>

                      <b-col md="12" lg="12">
                        <text-input
                          v-model="data.name"
                          size="lg"
                          placeholder="Project name"
                          label="Name"
                          label-class="font-weight-bold"
                          hit="The name used for the Api Url."
                          validation-rules="required|project-api-key"
                        />
                      </b-col>

                      <b-col cols="12">
                        <text-area v-model="data.description" size="lg" placeholder="Description" label="Description" />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex justify-content-center w-100">
          <div class="justify-content-start px-2 px-lg-2">
            <b-button variant="outline-primary" type="submit" class="" @click="cancel()">
              Cancel
            </b-button>
          </div>
          <div class="px-2 px-lg-2">
            <b-button variant="primary" type="submit" class="mr-50" @click="ok()" :disabled="creatingProject">
              Create
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import store from '../../../store'
import * as changeCase from 'change-case'
import { projects } from '@/api'
import * as validations from '@/libs/validations/validations.js'
import DefaultNotifications from '@/components/Notification/default'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'NewProject',
  mixins: [FormComponent],
  props: ['value'],
  components: {},
  data() {
    return {
      loading: false,
      data: { displayName: '', name: '' },
      openModal: false,
      creatingProject: false,
    }
  },
  watch: {
    value(newVal, oldVal) {
      this.openModal = newVal
    },
  },
  created() {},
  mounted() {},
  methods: {
    reset() {
      this.data = {}
      this.ResetErrors()
      this.$emit('input', false)
    },
    createKey(e) {
      this.data.name = changeCase.noCase(this.data.displayName, {
        delimiter: '-',
      })
    },
    async onSave(e) {
      e.preventDefault()

      const self = this

      const validation = await self.$refs.newProjectForm.validateWithInfo()

      if (validation.isValid) {
        self.creatingProject = true

        projects
          .createProject(self.data)
          .then(res => {
            self.ResetErrors()
            self.$emit('input', false)
            self.$emit('project-created')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
          .finally(() => {
            self.creatingProject = false
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
  },
  computed: {},
}
</script>
