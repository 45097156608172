<template>
  <div>
    <ProfileHeader />
    <b-container :fluid="false" class="d-flex flex-column justify-content-center align-items-start h-100 p-0">
      <ProjectList />
    </b-container>
    <ProjectMarketPlace class="mt-2" />
  </div>
</template>
<script>
import ProfileHeader from '@/components/Layout/ProfileHeader/ProfileHeader.vue'
import ProjectList from './components/ProjectList.vue'
import ProjectMarketPlace from './components/ProjectMarketPlace.vue'

export default {
  name: 'ProjectsDashboard',
  components: { ProfileHeader, ProjectList, ProjectMarketPlace },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
